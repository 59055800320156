// .list-group-item {
//   border: 0 !important;
//   border-radius: 10px !important;
// }
.form-check-input:checked {
  background-color: #ec6927 !important;
  border: #707070 !important;
}
mark {
  background-color: #e9d700 !important;
}
// end Bootstrap customization section
.form-control {
  &:focus {
    box-shadow: unset !important;
  }
}
.form-control:focus {
  border-color: unset !important;
  box-shadow: none !important;
}
// @MUI Stepper Custom Classess
.MuiBox-root {
  // .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  .MuiButtonBase-root.MuiIconButton-root {
    margin: 0px;
    padding: 0px;
  }

  .MuiStepContent-root {
    margin-left: 0px;
    margin-top: 0px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    @media screen and (min-width: 768px) {
      padding-left: 20px !important;
      padding-right: 7px !important;
      margin-left: 21px;
      margin-top: -12px;
    }
  }
  // .css-8t49rw-MuiStepConnector-line {
  .MuiStepConnector-line {
    border-left-width: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 0px;
    min-height: 0px;
    @media screen and (min-width: 768px) {
      border-color: #e8e9eb !important;
      border-left-width: 17px !important;
      margin-bottom: -12px !important;
      margin-left: 9px;
      min-height: 10px;
    }
  }
  // .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  .MuiSvgIcon-root.MuiStepIcon-root.Mui-active {
    color: #000 !important;
    width: 43px;
    height: 43px;
  }
  // .css-vnkopk-MuiStepLabel-iconContainer {
  .MuiStepLabel-iconContainer {
    padding-right: 0px !important;
    border: 8px solid #fff !important;
    border-radius: 50% !important;
  }
  // .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  .MuiSvgIcon-root.MuiStepIcon-root.Mui-completed {
    color: #4c0b86 !important;
  }
  .Mui-completed {
    // .css-8t49rw-MuiStepConnector-line {
    .MuiStepConnector-line {
      border-color: #4c0b86 !important;
    }
  }

}
input[type=text]{
  transition: none !important;
  -webkit-transition: none !important;
  -o-transition: none !important;
}
input[type=text]:focus{
  outline: unset !important;
  outline-offset: 0px !important;
}
// Edit Material For Login And Register Inputs
.MuiFormControl-root {
  // .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {

  // .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  // .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
  }
  // .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  .MuiInputBase-root.MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}
// .css-117w1su-MuiStepIcon-text{
.MuiStepIcon-text {
  font-size: 12px !important;
  font-family: "LexendDeca-Regular" !important;
  font-weight: bold !important;
}
.MuiTelInput-Menu{
  height:50%
}
.englease-rtl {
  .MuiBox-root {
    .MuiSvgIcon-root.MuiStepIcon-root.Mui-completed {
      color: #4c0b86 !important;
    }

    .MuiStepConnector-line {
      border-color: #e8e9eb !important;
      border-right-width: 17px !important;
      margin-bottom: -12px !important;
      width: max-content;
      padding-right: 21px;
    }
    .MuiStepLabel-iconContainer {
      padding-left: 0px !important;
      border: 8px solid #fff !important;
      border-radius: 50% !important;
    }
    .MuiStepLabel-label {
      width: max-content !important;
    }
    .MuiStepContent-root {
      padding-left: 0px !important;
      padding-right: 0px !important;
      margin-right: 0px;
      margin-top: 0px;
      @media screen and (min-width: 768px) {
        padding-left: 7px !important;
        padding-right: 20px !important;
        border-left: 0px !important;
        margin-left: 0px !important;
        margin-right: 21px;
        margin-top: -12px;
      }
    }
  }
  .MuiFormControl-root {
    .MuiOutlinedInput-notchedOutline {
      text-align: right !important;
    }
    .MuiFormHelperText-root {
      text-align: right !important;
    }
    .MuiFormLabel-root.MuiInputLabel-root{
      width: max-content;
    }
  }
  .MuiStepIcon-text {
    font-size: 12px;
    font-family: "Cairo-Bold" !important;
    font-weight: bold !important;
  }
  .MuiInputBase-adornedStart {
    direction: ltr !important;
  }
  .MuiTelInput-Flag .MuiTelInput-IconButton .MuiTelInput-Menu .MuiTelInput-MenuItem{
    padding-left: 0 !important;

  }
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}
