.iET-user-dark {
  background-image: url("../../public/assets/icons/Userdark.svg");
}
.iET-classlist-black {
  background-image: url("../../public/assets/icons/Classlistblack.svg");
}
.iET-calendar-black {
  background-image: url("../../public/assets/icons/Calendarblack.svg");
}
.iET-time-black {
  background-image: url("../../public/assets/icons/Timeblack.svg");
}
.iET-time-gray {
  background-image: url("../../public/assets/icons/Timegray.svg");
}

.iET-close {
  background-image: url("../../public/assets/icons/X.svg");
}
.iET-certificate-yellow {
  background-image: url("../../public/assets/icons/certificateYellow.svg");
}
.iET-locked {
  background-image: url("../../public/assets/icons/locked.svg");
}
.iET-Menublack {
  background-image: url("../../public/assets/icons/Menublack.svg");
}
.iET-Global {
  background-image: url("../../public/assets/icons/Global.svg");
}
.iET-Logout {
  background-image: url("../../public/assets/icons/logout.svg");
}
.iET-Logoutorange {
  background-image: url("../../public/assets/icons/Logoutorange.svg");
}
.iET-Eyes-show {
  background-image: url("../../public/assets/icons/Showpassword.svg");
}
.iET-Eyes-hide {
  background-image: url("../../public/assets/icons/Hidepassword.svg");
}
.iET-Close-Message {
  background-image: url("../../public/assets/icons/CloseMessage.svg");
}
.iET-Close-Icon {
  background-image: url("../../public/assets/icons/CloseIcon.svg");
}
.iET-Success-Message {
  background-image: url("../../public/assets/icons/SuccessMsg.svg");
}
.iET-Correct-Answer {
  background-image: url("../../public/assets/icons/CheckMark.svg");
}
.iET-Wrong-Answer {
  background-image: url("../../public/assets/icons/Wrong.svg");
}
.iET-celebration {
  background-image: url("../../public/assets/icons/celebration.svg");
}
.iET-expired-warning {
  background-image: url("../../public/assets/icons/warning.svg");
}
.iET-linkedin {
  background-image: url("../../public/assets/icons/linkedin.svg");
}
.iET-right-arrow{
  background-image: url("../../public/assets/icons/RightArrow.png");
}
.iET-download-materials{
  background-image: url("../../public/assets/icons/Download.png");
}
.iET-practice-study{
  background-image: url("../../public/assets/icons/Practice.png");
}
//  START === Icon Size

.iET-s16 {
  width: 16px;
  height: 16px;
}
.iET-s21 {
  width: 21px;
  height: 21px;
}
.iET-s24 {
  width: 24px;
  height: 24px;
}
.iET-m80 {
  width: 80px;
  height: 80px;
}
// END === Icon Size
