@font-face {
	font-family: "LexendDeca-Bold";
	src: url("../../public/assets/Fonts/Lexend_Deca/static/LexendDeca-Bold.ttf") format("woff");
	font-weight: bold;
	font-display: swap;
}

@font-face {
	font-family: "LexendDeca-Regular";
	src: url("../../public/assets/Fonts/Lexend_Deca/static/LexendDeca-Regular.ttf") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}


@font-face {
	font-family: "Cairo-Regular";
	src: url("../../public/assets/Fonts/Cairo/static/Cairo-Regular.ttf") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Cairo-Bold";
	src: url("../../public/assets/Fonts/Cairo/static/Cairo-Bold.ttf") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

.englease {
	font-family: "LexendDeca-Regular" !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 16px !important;

	&--f-38 {
		font-size: 38px !important;
	}
	&--f-26 {
		font-size: 26px !important;
	}

	&--f-24 {
		font-size: 24px !important;
	}

	&--f-20 {
		font-size: 20px !important;
	}

	&--f-18 {
		font-size: 18px !important;
	}

	&--f-16 {
		font-size: 16px !important;
	}

	&--f-14 {
		font-size: 14px !important;
	}

	&--f-12 {
		font-size: 12px !important;
	}

	&--f-10 {
		font-size: 10px !important;
	}

	&--bold {
		font-family: "LexendDeca-Bold" !important;
		font-weight: bold;
	}
}

.englease-rtl {
	font-family: "Cairo-Regular" !important;
	font-style: normal !important;
	font-size: 16px !important;

	&--bold {
		font-family: "Cairo-Bold" !important;
		font-weight: bold;
	}

	.englease {
		font-family: "Cairo-Regular" !important;

		&--bold {
			font-family: "Cairo-Bold" !important;
		}
	}
}