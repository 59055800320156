.btn-radio{
  cursor: pointer;
  background-color: #F8F8F8;
  display: flex;
	align-items: center;
  gap: 16px;
  -webkit-user-select: none;
  user-select: none;
  &:hover{
		background-color: var(--color-Lavender);
    }
  &:not(:first-child){
    margin-left: 20px
  }
  @media screen and (max-width: 480px) {
    float: none;
    &:not(:first-child){
      margin-left: 0;
      margin-top: 15px;
    }
  }
  .svg-holder{
    svg{
      width: 24px;
      height: 24px;
      fill: none;
      vertical-align: middle;
      circle{
        width: 24px;
        height: 24px;
        stroke-width: 2;
        stroke: #D0D5DD;
      }
      path{
        width: 24px;
        height: 24px;
        stroke: #4c0b86;
        &.inner{
          width: 24px;
          height: 24px;
          stroke-width: 6;
          stroke-dasharray: 19;
          stroke-dashoffset: 19;
        }
        &.outer{
          width: 24px;
          height: 24px;
          stroke-width: 2;
          stroke-dasharray: 57;
          stroke-dashoffset: 57;
        }
      }
    }
  }

  input{
    display: none;
    &:checked + .svg-holder svg{
      width: 24px;
      height: 24px;
      path{
        transition: all .4s ease;
        &.inner{
          stroke-dashoffset: 38;
          transition-delay: .3s;
        }
        &.outer{
          stroke-dashoffset: 0;
        }
      }
    }
  }
}
