.attend-assessment {
  
  &__survey-title{
    text-align: start;
  }
  &__survey-list{
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
    @media (min-width: 992px) {
      grid-template-columns: repeat(auto-fill, 120px);
    }
    @media (min-width: 768px) and (max-width: 992px){
      grid-template-columns: repeat(4, 1fr);
    }
    @media (min-width: 576px) and (max-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &__btn{
      padding: 0.75rem 4rem;
  }
  &__spinner{
    display: flex;
    justify-content: center;
    align-items: center;
      height: 80vh;
    &--circle{
      width: 3rem;
      height: 3rem;
    }
  }
}

.englease-rtl{
  direction: rtl;
  .attend-assessment {
    &__survey{
     &--title{
       text-align: start;
     }
     }
   }
}